// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-projects-projects-graphql-js": () => import("./../src/components/Projects/projects-graphql.js" /* webpackChunkName: "component---src-components-projects-projects-graphql-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-bathroom-remodeling-js": () => import("./../src/pages/services/bathroom-remodeling.js" /* webpackChunkName: "component---src-pages-services-bathroom-remodeling-js" */),
  "component---src-pages-services-custom-carpentry-js": () => import("./../src/pages/services/custom-carpentry.js" /* webpackChunkName: "component---src-pages-services-custom-carpentry-js" */),
  "component---src-pages-services-decks-and-porches-js": () => import("./../src/pages/services/decks-and-porches.js" /* webpackChunkName: "component---src-pages-services-decks-and-porches-js" */),
  "component---src-pages-services-home-additions-js": () => import("./../src/pages/services/home-additions.js" /* webpackChunkName: "component---src-pages-services-home-additions-js" */),
  "component---src-pages-services-home-remodeling-js": () => import("./../src/pages/services/home-remodeling.js" /* webpackChunkName: "component---src-pages-services-home-remodeling-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-kitchen-remodeling-js": () => import("./../src/pages/services/kitchen-remodeling.js" /* webpackChunkName: "component---src-pages-services-kitchen-remodeling-js" */)
}

